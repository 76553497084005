import React, { useState } from "react";
import styles from "./../../../sass/landing/main.module.scss";
import classNames from "classnames";
import IconButton from "./../IconButton";
import ViridiusIcon from "./../ViridiusIcon";
import SubscribeModal from "./../SubscribeModal";
import { Divider, Typography } from "@mui/material";
import accent_background from '../../../Landing/assets/accent-background.png';
import logos from '../../../Landing/assets/viridius-logos.png';
import iam_logos from '../../../Landing/assets/iam-logos.png';
import illustration from '../../../Landing/assets/about-illustration.png';

const Body = () => {
  const [open, setOpen] = useState(false);

  const handleDeckPaywall = () => {
    const isSubbed = localStorage.getItem("subbed");
    if(isSubbed) {
      window.open("/PureSkyPitchDeck-V2.pdf");
    } else {
      setOpen(true);
    }
  }

  return (
    <>
      <img src={accent_background} className="front-page-background-1 img-fluid" alt="Background 1"/>
      <div className={classNames(styles["main"], "container")}>
        <div className="row">
          <div className="col-md-6 col-12">
            <ViridiusIcon icon={null}/>
            <ul>
              <li><h3>Trust</h3></li>
              <li><h3>Value</h3></li>
              <li><h3>Transparency</h3></li>
            </ul>
            <img 
              src={illustration}
              className="img-fluid mb-md-0 mb-5" 
              alt="Illustration"
            />
          </div>
          <div className="col-md-5 col-12">
            <Typography variant="body1">
              As things stand today, the carbon credits market is exploding. It’s grown from $306M to $1B in the last year alone. There’s just one problem... There's no decentralized, trustworthy verification platform.
            </Typography>
            <Typography variant="body1">
              That ends today with Pure Sky.
            </Typography>
            <Typography variant="body1">
              Pure Sky is building a modern, community-driven carbon credit registry that will solve the massive problem in this new megatrend. Viridius removes the guess work and does the heavy lifting for carbon credit investors.
            </Typography>
            <Typography variant="body1">
              Pure Sky is a Decentralized Autonomous Organization (DAO). Essentially a DAO is a type of company structure that operates with no central governing body, but instead unites all stakeholders through a common, forward-looking goal.
            </Typography>
            <Typography variant="body1">
              In Pure Sky's case, that common goal is a carbon credit ratings agency that’s controlled by investors and directed through a weighted vote.
            </Typography>
            <button className="naked-button" onClick={() => handleDeckPaywall()}>
              <IconButton icon="summary" copy={"Learn More"}/>
            </button>
            <Typography variant="h4">Corporate Members</Typography>
            <img src={logos} className={classNames(styles["logos"], "img-fluid mb-3")} alt="Partners"/>
            <Divider/>
            <Typography variant="h4">International Indigenous Members</Typography>
            <img src={iam_logos} className={classNames(styles["logos"], "img-fluid mb-5")} alt="Partners"/>
          </div>
        </div>
      </div>
      <SubscribeModal
        isOpened={open}
        closeModal={() => setOpen(false)}
        successUrl="/PureSkyPitchDeck-V2.pdf"
        submitButtonLabel="Learn More"
      />
    </>
  );
};

export default Body;
