import React, { useEffect } from "react";
import Body from "./Landing/components/Main/Body";
import Hero from "./Landing/components/Main/Hero";
import Footer from "./Landing/components/Footer";
import Header from "./Header";

const Home = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header/>
      <Hero />
      <Body />
      <Footer/>
    </>
  );
};

export default Home;
