import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./sass/bootstrap.scss";
import "./sass/app.scss";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import store from "./store";

// Styling
import theme from "./theme";

// Utilities
import Tracker from "./GoogleAnalytics.js";

// Pages
import Home from "./Home";
import About from "./About";
import CarbonMarket from "./CarbonMarket";
import Team from "./Team";
import Legal from "./Legal";
import FeeStructure from "./FeeStructure";
import Faq from "./Faq";
import Methodology from "./Methodology";
import Standard from "./Standard";
import Vvb from "./Vvb";
import Member from "./Member";
import Projects from "./Projects.js";
import Media from "./Media.js";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Switch>
            <Route exact path="/" component={Tracker(Home)} />
            <Route exact path="/about" component={Tracker(About)} />
            <Route exact path="/carbon-market" component={Tracker(CarbonMarket)} />
            <Route exact path="/team" component={Tracker(Team)} />
            <Route exact path="/tos" component={Tracker(Legal)} />
            <Route exact path="/fees" component={Tracker(FeeStructure)} />
            <Route exact path="/vvb" component={Tracker(Vvb)} />
            <Route exact path="/faq" component={Tracker(Faq)} />
            <Route exact path="/methodology" component={Tracker(Methodology)} />
            <Route exact path="/member" component={Tracker(Member)} />
            <Route exact path="/pure-sky-standard" component={Tracker(Standard)} />
            <Route exact path="/projects" component={Tracker(Projects)} />
            <Route exact path="/media" component={Tracker(Media)} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
