import styles from "./../../../sass/landing/hero.module.scss";
import classNames from "classnames";
import { Typography } from "@mui/material";

const Header = () => {
  return (
    <>
      <div className={classNames(styles["about-header"],styles["about"], styles["background"])}>
        <div className={classNames(styles["tint"])}/>
        <div className="container h-100">
          <div className={classNames(styles["slogan"], "row")}>
            <div className="col-md-6 col-12">
              <h1>
                About<br/>Pure Sky
              </h1> 
            </div>
            <div className="col-md-5 col-12">
              <Typography variant="body1">
                The Pure Sky Registry is a Decentralized Autonomous Organization (DAO). Essentially a DAO is a type of company structure that operates with no central governing body, but instead unites all stakeholders through a common, forward-looking goal.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
