import React from "react";
import Footer from "./Landing/components/Footer";
import styles from "./sass/subpage.module.scss";
import classNames from "classnames";
import { 
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, 
} from "@mui/material";
import Header from './Header';
import ViridiusIcon from "./Landing/components/ViridiusIcon";

const FeeStructure = (props) => {
  return (
    <>
      <Header/>
      <div className={classNames(styles["subpage"], "container")}>
        <div className="row my-5">
          <div className="col-12">
            <ViridiusIcon icon={null}/>
            <Typography variant="h3">Pure Sky<br/>Fee Structure</Typography>
          </div>
        </div>
        <div className="row mb-5 justify-content-center">
          <div className="col-md-8 col-12">
            <Typography variant="body1" className="mb-2">
              The following fee structure is effective <strong>May 3, 2023</strong>, for Pure Sky project and methodology review. All fees are due on the date of the transaction and any reimbursements will be issued and paid within 30 days of the project/methodology approval.
            </Typography>
            <Typography variant="body1" className="mb-2">
              Project developers must be a <a href="https://registry.puresky.earth/" target="_blank">Pure Sky Registry</a> member to transact on the Registry. As a Pure Sky member, you will have exclusive benefits and an inside look at emerging projects and trends in the carbon credit ecosystem.
            </Typography>
            <Typography variant="body1" className="mb-2">
              All fees are quoted in US dollars (USD).
            </Typography>
          </div>  
          <div className="col-md-9 col-12">
            <TableContainer className={classNames(styles["ps-table"], "mt-3 mb-5")}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Fee</TableCell>
                    <TableCell>Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Membership Fee & Account Creation</strong>
                    </TableCell>
                    <TableCell>
                      Membership fees range from $1,000 to $5,000 USD and are tailored to the members' needs. Members are given access to the full suite of Pure Sky services and associated dividends from the transactions, if applicable. Once a member, an account can be created on the registry.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Project Submission</strong>
                    </TableCell>
                    <TableCell>
                      $5,000 (fully refundable fee) for project submission to the registry. If the project is approved by the membership, the project developer will be reimbursed for the project submission fee.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Methodology Submission</strong>
                    </TableCell>
                    <TableCell>
                      $5,000 (fully refundable fee) for a new methodology submission to the registry. If the methodology is approved by the membership, the methodology developer will be reimbursed for the methodology submission fee.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Insurance</strong>
                    </TableCell>
                    <TableCell>
                      $0.05 per credit issued.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{borderBottom:"none"}}>
                      <strong>Retirement</strong>
                    </TableCell>
                    <TableCell style={{borderBottom:"none"}}>
                      No fee for retirement of credits.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="col-md-8 col-12">
            <Typography variant="h6">Pure Sky Process</Typography>
            <Typography variant="body1">
              Project developers must become a Pure Sky member to open an account on the Pure Sky registry. Before project submission, project developers should:
            </Typography>
            <ul>
              <li><Typography variant="body1">Review the Pure Sky Standard,</Typography></li>
              <ul>
                <li><Typography variant="body1">All projects submissions must follow the format outlined in the standard and adhere to CDM and UNFCC standards</Typography></li>
              </ul>
              <li><Typography variant="body1">Ensure the methodology used by the project developer is an approved Pure Sky methodology,</Typography></li>
              <li><Typography variant="body1">Have the project reviewed by a third-party validation and verification body,</Typography></li>
              <li><Typography variant="body1">Review Pure Sky’s submission check list.</Typography></li>
            </ul>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default FeeStructure;
