import React from "react";
import Footer from "./Landing/components/Footer";
import styles from "./sass/subpage.module.scss";
import classNames from "classnames";
import { 
  Typography,
} from "@mui/material";
import Header from './Header';
import ViridiusIcon from "./Landing/components/ViridiusIcon";

const Methodology = (props) => {
  return (
    <>
      <Header/>
      <div className={classNames(styles["subpage"], "container")}>
        <div className="row my-5">
          <div className="col-12">
            <ViridiusIcon icon={null}/>
            <Typography variant="h3">Methodologies</Typography>
          </div>
        </div>
        <div className="row mb-5 justify-content-center">
          <div className="col-md-8 col-12">
            <Typography variant="body1" className="mb-2">
              Pure Sky recognizes the high-quality methodologies that exist within other registries. Pure Sky grandfathers all methodologies approved on the CDM Registry as supported by the UNFCCC, <a href="https://registry.verra.org/app/projectDetail/VCS/1704" target="_blank">VERRA</a> and <a href="https://www.goldstandard.org/tags/methodologies" target="_blank">Gold Standard</a>. Pure Sky still requires a qualified and approved Pure Sky validation and verification body (VVB) review all projects to qualify for Pure Sky credits.  Pure Sky is not responsible for edits changes or omissions found within 3rd party registries and reserves the right to reject any of these methodologies as per a membership vote.  When in doubt about a qualifying methodology, please contact the Pure Sky administrator at <a href="mailto:info@puresky.earth">info@puresky.earth</a>.
            </Typography>
          </div>  
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Methodology;
