import React from "react";
import Footer from "./Landing/components/Footer";
import styles from "./sass/subpage.module.scss";
import classNames from "classnames";
import { 
  Typography,
} from "@mui/material";
import Header from './Header';
import ViridiusIcon from "./Landing/components/ViridiusIcon";

const Faq = (props) => {
  return (
    <>
      <Header/>
      <div className={classNames(styles["subpage"], "container")}>
        <div className="row my-5">
          <div className="col-12">
            <ViridiusIcon icon={null}/>
            <Typography variant="h3">FAQs</Typography>
          </div>
        </div>
        <div className="row mb-5 justify-content-center">
          <div className="col-md-8 col-12">
            <Typography variant="body1" className="mb-2">
              Coming Soon
            </Typography>
          </div>  
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Faq;
