import React, {useEffect} from "react";
import Body from "./Landing/components/Team/Body";
import Footer from "./Landing/components/Footer";
import Header from './Header';

const Team = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header/>
      <Body />
      <Footer/>
    </>
  );
};

export default Team;
