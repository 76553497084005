import React from "react";
import { Link } from "react-router-dom";
import logo from '../Landing/assets/logo.png';

export default function Logo() {
  return (
    <Link to="/" className="logo-placeholder d-flex align-items-center">
      <img src={logo} alt="Pure Sky Registry Logo" className="img-fluid"/>
    </Link>
  );
}
