import React from "react";
import styles from "./../../sass/landing/footer.module.scss";
import classNames from "classnames";
import {
  facebook,
  twitter,
  linkedin,
  instagram,
  tiktok
} from './../assets/icons/social/';

const Footer = (props) => {
  return (
    <div style={{background:"#22654e"}}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={classNames(styles["footer"])}>
              <div className="row">
                <div className={classNames(styles["legal"],"col-md-6 col-12")}>
                  <div className="mb-1">
                    Pure Sky Registry, LLC. &mdash; <a href="/tos">ToS</a> <a href="/PureSkyPitchDeck-V2.pdf" target="_blank">Executive Summary</a>
                  </div>
                  All rights reserved &copy; {new Date().getFullYear()}
                </div>
                <div className={classNames(styles["social"],"col-md-6 col-12 align-items-center d-flex justify-content-center justify-content-md-end mt-4 mt-md-0")}>
                  <a href="https://www.facebook.com/ViridiusDAO" target="_blank" className="me-3" rel="noreferrer">
                    <img src={facebook} className="facebook" alt="Facebook"/>
                  </a>
                  <a href="https://twitter.com/viridiusdao" target="_blank" className="me-3" rel="noreferrer">
                    <img src={twitter} className="twitter" alt="Facebook"/>
                  </a>
                  <a href="https://www.instagram.com/viridiusdao/" target="_blank" className="me-3" rel="noreferrer">
                    <img src={instagram} className="instagram" alt="Instagram"/>
                  </a>
                  <a href="https://www.linkedin.com/company/viridiusdao/" target="_blank" className="me-3" rel="noreferrer">
                    <img src={linkedin} className="linkedin" alt="Linkedin"/>
                  </a>
                  <a href="https://www.tiktok.com/@viridiusdao" target="_blank" rel="noreferrer">
                    <img src={tiktok} className="linkedin" alt="Linkedin"/>
                  </a>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
