import React, { useEffect, useState } from "react";
import styles from "./../../sass/landing/hero.module.scss";
import classNames from "classnames";
import { Dialog, useMediaQuery, DialogContent, IconButton, Typography, TextField, Alert, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTheme } from '@mui/material/styles';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { CloseCircleOutline } from "mdi-material-ui";

const CustomForm = ({ status, message, onValidated, extraFields, submitButtonLabel, successUrl, onSuccess }) => {

  // state
  const [response, setResponse] = useState("");
  const [feedback, setFeedback] = useState("");

  // misc hooks
  const { handleSubmit, control, errors, setValue } = useForm();

  // lifecycles
  useEffect(() => {
    setResponse(status);
    setFeedback(message);
    if(status === "success") {
      setValue("email", "");
      if(extraFields) {
        setValue("name", "");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status,message]);

  useEffect(() => {
    if(response === "success" && successUrl) {
      window.open(successUrl);
      localStorage.setItem("subbed", true);
      onSuccess();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[response]);

  const submit = (form) => {
    if(extraFields) {
      onValidated({
        EMAIL: form.email,
        FNAME: form.name,
        COMMENT: "Pure Sky Registry, LLC."
      });
      return;
    }
    onValidated({
      EMAIL: form.email,
    });
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
    >
      {
        extraFields && (
          <Controller
            as={
              <TextField
                label="Name"
                autoComplete="new-password"
                fullWidth={true}
                variant="outlined"
                error={errors.name && true}
                helperText={errors?.name?.message}
                className="mb-3 mt-2"
                inputProps={{ maxLength: 30 }}
              />
            }
            name="name"
            control={control}
            defaultValue={""}
            rules={{
              required: "This field is required.",
            }}
          />
        )
      }
      <Controller
        as={
          <TextField
            label="E-mail"
            autoComplete="new-password"
            fullWidth={true}
            variant="outlined"
            error={errors.email && true}
            helperText={errors?.email?.message}
            className="mb-3"
            inputProps={{ maxLength: 30 }}
          />
        }
        name="email"
        control={control}
        defaultValue={""}
        rules={{
          required: "This field is required.",
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            message: "Please enter a valid email address.",
          },
        }}
      />
      {
        response === "error" && (
          <Alert severity="error" className="mb-3">
            {feedback}
          </Alert>  
        )
      }
      {
        response === "success" && (
          <Alert severity="success" className="mb-3">
            {feedback}
          </Alert>  
        )
      }
      <Button
        disabled={response === "sending"}
        size="large"
        variant="contained"
        color="primary"
        type="submit"
      >
        {submitButtonLabel ? submitButtonLabel : "Subscribe"}
      </Button>
    </form>
  )
}

const SubscribeModal = ({isOpened, closeModal, successUrl, submitButtonLabel}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Dialog 
        fullScreen={isFullScreen}
        open={isOpened}
        onClose={() => closeModal()}
        sx={{
          '& .MuiPaper-root': {
            backgroundImage: "linear-gradient(90deg, #8642fe 0%, #1b81f9 50%, #08f0bf 100%)",
            padding: "5px",
          },
        }}
      >
        <div className={classNames(styles["modal-wrapper"],"w-100")}>
          <div className={classNames(styles["modal-header"],"d-flex justify-content-between align-items-center")}>
            <Typography variant="h6">One more step...</Typography>
            <IconButton size="large" onClick={() => closeModal()}>
              <CloseCircleOutline />
            </IconButton>
          </div>
          <DialogContent sx={{ minWidth: isFullScreen ? "auto" : "400px !important" }}>
            <MailchimpSubscribe
              url={'https://bluespherecarbon.us1.list-manage.com/subscribe/post?u=dd44cd897074f9847d1fd163a&amp;id=d07142e5a3'}
              render={({ subscribe, status, message }) => (
                <>
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={
                      formData => subscribe(formData)
                    }
                    props={this}
                    extraFields={true}
                    submitButtonLabel={submitButtonLabel}
                    successUrl={successUrl}
                    onSuccess={() => {
                      closeModal();
                    }}
                  />
                </>
              )}
            />
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default SubscribeModal;
