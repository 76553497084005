import React, { useEffect, useState } from "react";
import Footer from "./Landing/components/Footer";
import styles from "./sass/subpage.module.scss";
import classNames from "classnames";
import { 
  Typography,
} from "@mui/material";
import Header from './Header';
import ViridiusIcon from "./Landing/components/ViridiusIcon";
import axios from "axios";
import { Skeleton, Stack, Link } from '@mui/material';

const Media = () => {
  // eslint-disable-next-line no-undef
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchPressReleases = async () => {
      try {
        const response = await axios.get('https://viridius-1edc502f63d1.herokuapp.com/press');
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching press releases:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPressReleases();
  },[]);

  return (
    <>
      <Header/>
      <div className={classNames(styles["subpage"], styles["standard"], "container")}>
        <div className="row my-5">
          <div className="col-12">
            <ViridiusIcon icon={null}/>
            <Typography variant="h3">Media</Typography>
          </div>
        </div>
        <div className="row mb-5 justify-content-center">
          <div className={classNames(styles["media"], "col-md-8 col-12")}>
            
            {
              loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded"/>
                  <Skeleton variant="rounded"/>
                  <Skeleton variant="rounded"/>
                </Stack>
              ) : (
                <>
                  {
                    items.length === 0 ? (
                      <Typography variant="body1" className="mb-2">
                        Nothing found. Please come back soon.
                      </Typography>
                    ) : items.map(item => {
                      const { id, title, url, source_name } = item;
                      return (
                        <div key={id} className="mb-5">
                          <p className="mb-2">{source_name} </p>
                          <Link href={url} target="_blank" rel="noreferrer"><Typography variant="h4">{title}</Typography></Link>
                        </div>
                      )
                    })
                  }
                </>
              )
            }
            
          </div>  
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Media;