import styles from "./../../../sass/landing/hero.module.scss";
import classNames from "classnames";
import { Typography } from "@mui/material";

const Header = () => {
  return (
    <>
      <div className={classNames(styles["carbon-market-header"],styles["carbon-market"],styles["background"])}>
        <div className={classNames(styles["tint"])}/>
        <div className="container h-100">
          <div className={classNames(styles["slogan"], "row")}>
            <div className="col-md-6 col-12">
              <h1>
                The Carbon<br/>Credit Market
              </h1> 
            </div>
            <div className="col-md-5 col-12">
              <Typography variant="body1">
                You've heard it before. The latest mega-corporation is committing to greener practices, promising to be net-zero by 2030, 2040, or 2050. As financial markets continue to consider environmental impact, green-focused companies are the frontrunners for new investments.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
