import React from "react";
import styles from "./../../sass/landing/viridius-icon.module.scss";
import classNames from "classnames";

const ViridiusIcon = ({ icon }) => {
  return (
    <div
      className={classNames(
        styles["icon-wrap"],
        "d-inline-block"
      )}
    >
      <div className={classNames(
        styles["sphere1"]
      )}/>
      <div className={classNames(
        styles["sphere2"]
      )}/>
    </div>
  );
}
export default ViridiusIcon;
