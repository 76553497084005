import React, { useState, forwardRef } from "react";
import {  NavLink, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
import classNames from "classnames";
import Logo from "../components/Logo";
import { IconButton, Menu, MenuItem, Link } from "@mui/material";
import { DotsHorizontal, MenuDown } from "mdi-material-ui";

const ForwardNavLink = forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

const Header = () => {
  // state
  const [marketingMenuAnchor, setMarketingMenuAnchor] = useState(null);
  const [contextualMenuAnchor, setContextualMenuAnchor] = useState(null);
  const [isHeaderFilled, setHeaderFilled] = useState(false);

  // hooks
  const location = useLocation();

  // methods
  const handleMarketingMenuToggle = (e) => {
    setMarketingMenuAnchor(e.currentTarget);
  };

  const handleContextualMenuToggle = (e) => {
    setContextualMenuAnchor(e.currentTarget);
  }

  const changeBackground = () =>{
    if (window.scrollY >= 80){
      setHeaderFilled(true);
    } else{
      setHeaderFilled(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <>
      <div 
        className={
          classNames(
            styles["header"], 
            (isHeaderFilled || 
              location.pathname === "/team" || 
              location.pathname === "/tos" || 
              location.pathname === "/fees" ||
              location.pathname === "/vvb" ||
              location.pathname === "/faq" ||
              location.pathname === "/pure-sky-standard" ||
              location.pathname === "/methodology" ||
              location.pathname === "/member"
              ) && styles["filled"], 
            "container-fluid"
          )
        }>
        <div className="row align-items-center h-100">
          <div className="col-md-3 col-6">
            <Logo />
          </div>
          <div className={classNames(styles["menu"], "col-6 text-center justify-content-center d-md-flex d-none")}>
            <ul>
              <li><NavLink exact to="/" activeClassName={styles["active"]}>Home</NavLink></li>
              <li><NavLink exact to="/about" activeClassName={styles["active"]}>About</NavLink></li>
              <li><a href="https://registry.puresky.earth" activeClassName={styles["active"]}>Registry</a></li>
              <li><NavLink exact to="/carbon-market" activeClassName={styles["active"]}>Carbon Market</NavLink></li>
              <li><NavLink exact to="/team" activeClassName={styles["active"]}>Team</NavLink></li>
              <li><a href="#" onClick={handleContextualMenuToggle}>Resources <MenuDown/></a></li>
              <li><NavLink exact to="/media" activeClassName={styles["active"]}>Media</NavLink></li>
              <li><NavLink exact to="/member">Become a Member</NavLink></li>
              <Menu
                variant="menu"
                anchorEl={contextualMenuAnchor}
                keepMounted={false}
                open={Boolean(contextualMenuAnchor)}
                onClose={() => setContextualMenuAnchor(null)}
                disableScrollLock={true}
              >
                <MenuItem
                  component={ForwardNavLink}
                  to={"/pure-sky-standard"}
                  onClick={() => setContextualMenuAnchor(null)}
                >
                  Pure Sky Standard
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/fees"}
                  onClick={() => setContextualMenuAnchor(null)}
                >
                  Pure Sky Fee Structure
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/faq"}
                  onClick={() => setContextualMenuAnchor(null)}
                >
                  FAQs
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/methodology"}
                  onClick={() => setContextualMenuAnchor(null)}
                >
                  Methodologies
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/vvb"}
                  onClick={() => setContextualMenuAnchor(null)}
                >
                  Verification and Validation Bodies (VVB)
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/projects"}
                  onClick={() => setContextualMenuAnchor(null)}
                >
                  Projects
                </MenuItem>
              </Menu>
            </ul>
          </div>
          <div className="col-md-3 col-6 justify-content-end d-flex align-items-center">
            <IconButton className="d-block d-md-none" onClick={handleMarketingMenuToggle}>
              <DotsHorizontal className={classNames(styles["mobile-menu"])}/>
            </IconButton>
            <Menu
              variant="menu"
              anchorEl={marketingMenuAnchor}
              keepMounted={false}
              open={Boolean(marketingMenuAnchor)}
              onClose={() => setMarketingMenuAnchor(null)}
              disableScrollLock={true}
            >
              <MenuItem
                component={ForwardNavLink}
                to={"/"}
                onClick={() => setMarketingMenuAnchor(null)}
              >
                Home
              </MenuItem>
              <MenuItem
                component={Link}
                href={"https://registry.puresky.earth"}
                onClick={() => setMarketingMenuAnchor(null)}
              >
                Registry
              </MenuItem>
              <MenuItem
                component={ForwardNavLink}
                to={"/about"}
                onClick={() => setMarketingMenuAnchor(null)}
              >
                About
              </MenuItem>
              <MenuItem
                component={ForwardNavLink}
                to={"/carbon-market"}
                onClick={() => setMarketingMenuAnchor(null)}
              >
                Carbon Market
              </MenuItem>
              <MenuItem
                component={ForwardNavLink}
                to={"/team"}
                onClick={() => setMarketingMenuAnchor(null)}
              >
                Team
              </MenuItem>
              <MenuItem>
                Resources
              </MenuItem>
              <div className="ms-3">
                <MenuItem
                  component={ForwardNavLink}
                  to={"/pure-sky-standard"}
                  onClick={() => setMarketingMenuAnchor(null)}
                >
                  Pure Sky Standard
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/fees"}
                  onClick={() => setMarketingMenuAnchor(null)}
                >
                  Pure Sky Fee Structure
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/faq"}
                  onClick={() => setMarketingMenuAnchor(null)}
                >
                  FAQs
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/methodology"}
                  onClick={() => setMarketingMenuAnchor(null)}
                >
                  Methodologies
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/vvb"}
                  onClick={() => setMarketingMenuAnchor(null)}
                >
                  Verification and Validation Bodies (VVB)
                </MenuItem>
                <MenuItem
                  component={ForwardNavLink}
                  to={"/projects"}
                  onClick={() => setContextualMenuAnchor(null)}
                >
                  Projects
                </MenuItem>
              </div>
              <MenuItem
                component={ForwardNavLink}
                to={"/member"}
                onClick={() => setMarketingMenuAnchor(null)}
              >
                Become a Member
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
