import React from "react";
import Footer from "./Landing/components/Footer";
import styles from "./sass/subpage.module.scss";
import classNames from "classnames";
import { 
  Typography,
} from "@mui/material";
import Header from './Header';
import ViridiusIcon from "./Landing/components/ViridiusIcon";
import guardyan from "./Landing/assets/guardyan.png";

const Vvb = () => {
  return (
    <>
      <Header/>
      <div className={classNames(styles["subpage"], "container")}>
        <div className="row my-5">
          <div className="col-12">
            <ViridiusIcon icon={null}/>
            <Typography variant="h3">Verification and<br/>Validation Bodies</Typography>
          </div>
        </div>
        <div className={classNames(styles["vvb"], "row mb-5 justify-content-center")}>
          <div className="col-md-8 col-12">
            <img 
              src={guardyan} 
              width="100px"
              alt="Guardyan Conservation"
              className="mb-2"
            />
            <Typography variant="h4">Guardyan Conservation</Typography>
            <Typography variant="body2"><a href="https://www.guardyanconservation.com" target="_blank">https://www.guardyanconservation.com</a></Typography>
          </div>  
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Vvb;
