import { useState, useRef } from "react";
import styles from "./../../../sass/landing/hero.module.scss";
import classNames from "classnames";
import { CloseCircleOutline } from "mdi-material-ui"; 
import SubscribeModal from "../SubscribeModal";
import { IconButton, Dialog, useMediaQuery, Typography, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import YouTube from 'react-youtube';
import video_mp4 from "./../../assets/video/intro.mp4";
import video_webm from "./../../assets/video/intro.webm";
import video_ogv from "./../../assets/video/intro.ogv";

const Hero = () => {
  const [open, setOpen] = useState(false);
  const [video, setVideoOpen] = useState(false);
  const playerRef = useRef(null);
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleInvestPaywall = () => {
    /*
    const isSubbed = localStorage.getItem("subbed");
    if(isSubbed) {
      window.open("https://viridius-registry.netlify.app/");
    } else {
      setOpen(true);
    }
    */
    window.open("https://registry.puresky.earth/");
  }
  const playVideo = () => {
    playerRef.current.internalPlayer.playVideo();
  };
  return (
    <>
      <div className={classNames(styles["main"],styles["main-header"],styles["background"])}>
        <div className={classNames(styles["tint"])}/>
        <div className={classNames(styles["video-background"])}>
          <video autoPlay loop muted playsInline className={classNames(styles["video"])}> 
            <source src={video_mp4} type="video/mp4" /> 
            <source src={video_webm} type="video/webm" />
            <source src={video_ogv} type="video/ogv" /> 
          </video>
        </div>
        <div className={classNames(styles["inner-wrapper"], "container h-100")}>
          <div className="row h-100 align-items-center">
            <div className={classNames(styles["slogan"], "col-12")}>
              <h1 className={classNames(styles["line1"])}>
                We Help Combat
              </h1>
              <h1 className={classNames(styles["line2"])}>
                Climate Change
              </h1>
       
              <div className="d-flex mt-5 w-100 justify-content-center">
                <Button
                  onClick={() => handleInvestPaywall()}
                  className={classNames(styles["invest-button"])}
                >
                  Open Registry
                </Button>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div 
                className={classNames(
                  styles["sub-slogan"],
                  "col-xl-6 col-lg-8 col-12 text-center"
                )}
                >
                <Typography variant="body1">Pure Sky is building a modern, community-driven carbon credit registry that will solve the massive problem in this new megatrend of carbon credit trading.</Typography>
              </div>
            </div>       
          </div>
        </div>
      </div>
      <SubscribeModal
        isOpened={open}
        closeModal={() => setOpen(false)}
        successUrl="https://dalmorefg.com/"
        submitButtonLabel="Invest Now"
      />
      <Dialog 
        fullScreen={isFullScreen}
        fullWidth={true}
        maxWidth="lg"
        open={video}
        onClose={() => setVideoOpen(false)}
        sx={{
          '& .MuiPaper-root': {
            backgroundImage: "linear-gradient(90deg, #8642fe 0%, #1b81f9 50%, #08f0bf 100%)",
          },
        }}                
      >
        <IconButton 
          className="d-block d-lg-none"
          size="large" 
          onClick={() => setVideoOpen(false)}
          style={{
            position: "absolute",
            top:0,
            right:0
          }}
          >
          <CloseCircleOutline
            style={{
              color: "#fff",
              fontSize:40
            }}
          />
        </IconButton>
        <div className={classNames(styles["fullscreen"],"d-flex align-items-center")}>
          <YouTube
            videoId="QIhPW0OSM8Y"
            opts={{
              playerVars: {
                autoplay: 1,
              },
            }}
            ref={playerRef}
            onReady={() => {
              playVideo();
            }}
          />  
        </div>
      </Dialog>
    </>
  );
};

export default Hero;
