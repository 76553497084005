import React, { useState } from "react";
import styles from "./../../../sass/landing/team.module.scss";
import commonStyles from "./../../../sass/subpage.module.scss";
import classNames from "classnames";
import ViridiusIcon from "../ViridiusIcon";
import { Typography, IconButton, Dialog } from "@mui/material";
import { shidan, marty, deven, eddie, armita, peter, jonathan, natasha, george, johnny, lionel, chief } from "./../../assets/team/";
import { CloseCircleOutline } from "mdi-material-ui";

const Body = () => {

  const [drawer, setDrawer] = useState(false);
  const [memberId, setMemberId] = useState(null);

  const handleClose = () => {
    setDrawer(false);
  };

  const displayBio = (memberId) => {
    let members = management;
    if(memberId > 2) {
      members = advisors;
    }
    const bio = members.find((member) => memberId === member.id);
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Typography variant="h3">{bio?.name}</Typography>
          <IconButton size="large" onClick={() => setDrawer(false)}>
            <CloseCircleOutline />
          </IconButton>
        </div>
        <Typography dangerouslySetInnerHTML={{__html: bio?.bio}}/>
      </>
    )
  }

  return (
    <>
      <div className={classNames(styles["team"], commonStyles["subpage"], "container")}>
        <div className="row justify-content-between">
          <div className="col-12">
            <ViridiusIcon icon={null}/>
            <h3>Executive<br/>Leadership</h3>
            <div className="row my-5 justify-content-md-center">
              {
                management.map((member,i) => {
                  return (
                    <div className="col-lg-3 col-md-5 col-6 mb-4" key={i}>
                      <img 
                        src={member.avatar}
                        alt={`Avatar of ${member.name}`} 
                        className="img-fluid" 
                        onClick={() => {
                          setMemberId(member.id);setDrawer(true);
                        }}
                        />
                      <h4>{member.name}</h4>
                      <Typography className={classNames(styles["position"])}>{member.role}</Typography>
                    </div>
                  )
                })
              }
            </div>
            <ViridiusIcon icon={null}/>
            <h3>Advisory<br/>Board</h3>
            <div className="row my-5 justify-content-md-center">
              {
                advisors.map((member,i) => {
                  return (
                    <div className="col-lg-3 col-md-5 col-6 mb-4" key={i}>
                      <img 
                        src={member.avatar}
                        alt={`Avatar of ${member.name}`} 
                        className="img-fluid" 
                        onClick={() => {
                          setMemberId(member.id);setDrawer(true);
                        }}
                        />
                      <h4>{member.name}</h4>
                      <Typography className={classNames(styles["position"])}>{member.role}</Typography>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <Dialog 
        open={drawer} 
        onClose={() => handleClose()}
        >
        <div className={classNames(styles["bio"])}>
          {displayBio(memberId)}
        </div>
      </Dialog>
    </>
  );
};

export default Body;

const management = [{
  id: 0,
  name: "Eddie Soleymani",
  avatar: eddie,
  role: "Founding Member",
  bio: "Mr. Soleymani is a serial entrepreneur who founded the quant proprietary trading firm  Lynx Capital Partners. During its tenure as a prop firm, Lynx was home to some of the  top traders in the world and is now one of the major technology providers in the trading  and electronic brokerage industries. Mr. Soleymani is also the founder of Lucid  Absinthe; the first legal Absinthe in the US in 95 years. He is widely recognized as a  pioneer in the industry through spearheading the reintroduction and legalization of  Absinthe in North America. Mr. Soleymani received a BBA in finance from George  Washington University and an MBA from Hofstra University."
}, {
  id: 1,
  name: "Shidan Gouran",
  avatar: shidan,
  role: "Founding Member",
  bio: "Mr. Gouran is one of the earliest investors in the blockchain sector and a serial  entrepreneur who cofounded ventures in the telecommunications, consumer electronics and esports industries, including: Nuovotel, the first Canadian CLEC to offer  wholesale VoIP services in Canada; Jazinga, the developers of the only Skype  approved business communications solution sold through the official Skype shop,  prior to Microsoft’s acquisition of Skype; Home Jinni, the developers of a Smart TV  solution that powered the majority of first-generation Android Smart TVs; and  Gamesquare, a gaming venture which is now one of the leading Canadian public  companies in the esports sector. Mr. Gouran studied Pure Mathematics and Theoretical  Physics at the University of Western Ontario."
}, {
  id: 2,
  name: "Marty Seymour",
  avatar: marty,
  role: "Founding Member",
  bio: "Seymour has 25 years experience in business development and finance in agriculture and food. In Seymour's previous role with Farm Credit Canada, he engaged with national industry associations, government officials, and industry influencers to help advance the interests of Canadian agricultura in areas of Carbon Management, Carbon Sequestration and First Nations Agri-Business. Today, he is the Chief Operating Officer of Carbon RX and is actively involved in his family farm.<br/><br/>Seymour has been involved in multiple trade missions to South East Asia, Australia, and the US to advance the interests of Canadian agriculture. He has held various Board posts over the years including the economic development board seat of a First Nation, Innovation Saskatchewan and currently sits on the University of Saskatchewan's Board of Governors.<br/><br/>Seymour’s background provides a catalyst for Pure Sky, connecting his extensive agricultural experience with Pure Sky's revolutionary product for the carbon offsets market.",
}];

const advisors = [{
  id: 3,
  avatar: chief,
  name: "Chief R. Bellerose",
  role: "Advisor",
  bio: "A compassionate and experienced elected leader, Bellerose served for 17 years as the Chief of Muskowekwan First Nation, Treaty 4 Territory inSaskatchewan, Canada. Reginald currently serves as the Chairperson of the Council of Advisors for Carbon RX where his wisdom and extensive knowledge is an integral part of the vision and mission of Carbon RX.<br/><br/>As a longtime Chief of Muskowekwan First Nation, Bellerose has led initiatives that positively impacted First Nations, including several high-profile issues of both regional and national significance. Bellerose has demonstrated successful advocacy of First Nation jurisdiction and property rights, environmental sovereignty, and an enduring commitment to expanding economic self-sufficiency. Bellerose continues to be driven by supporting entrepreneurs and influencing national procurement processes.<br/><br/>Bellerose is an ambitious and forward-thinking leader who believes that the First Nations economy must be included in the carbon economy by using its competitive advantages of rights, lands, and jurisdiction to secure access to new markets, both nationally and internationally.",
}, {
  id: 4,
  avatar: deven,
  name: "Deven Soni",
  role: "Advisor",
  bio: "Mr. Soni is an experienced operations executive and investor. He spent several years  as a technology-focused investor at Goldman Sachs and Highland Capital Partners  where he helped fund several top technology businesses. He is the co-founder of  Wired Investors, a private equity fund focused on small cap buyouts. Mr. Soni is also a  founding director of Polymath, cofounder at Tokens.com, and an active investor in the  digital assets space.",
}, {
  id: 5,
  avatar: peter,
  name: "Peter Boockvar",
  role: "Advisor",
  bio: "Peter is the Chief Investment Officer at Bleakley Financial Group, a NJ based wealth management firm. He is also the Editor of The Boock Report, a macro market newsletter. Prior to joining Bleakley, he was the Chief Market Analyst at The Lindsey Group, a macro economic and market research firm founded by former Federal Reserve Governor Larry Lindsey. Before this, Peter worked as a macro analyst and portfolio manager for a brief time at Omega Advisors and had previously been a partner at Miller Tabak + Company where he was the equity strategist and a portfolio manager. He graduated magna cum laude from The George Washington University with a BBA in finance.",
}, {
  id: 6,
  avatar: jonathan,
  name: "Jonathan Bonchick",
  role: "Advisor",
  bio: "Since 2002, Jonathan has held the positions of V.P./ Director of Buying & Merchandising for Duty Free Americas, Vice President for UETA Inc. of Panama, and Executive Vice President for Innovative Liquors LLC. From 1993 until he joined Duty Free Americas/UETA Inc., Jonathan held various positions within Brown-Forman Beverages Worldwide in the USA Domestic Market, Military & Transportation.  In Jonathan's last position with Brown-Forman, he managed North America Duty Free and the Caribbean.  Jonathan is a founding partner who legalized Lucid Absinthe, the first legal Absinthe in the United States in 95 years.  The company successfully sold Lucid to Hood River Distillers, Inc. in March 2013.  Jonathan is the 3rd generation of his family in the spirits industry.  He earned a B.S. in Finance from the University of Maryland (1991), as well as an MBA in International Business from the University of Miami (1992).",
}, {
  id: 7,
  name: "Lionel Kambeitz",
  avatar: lionel,
  role: "Advisor",
  bio: "Kambeitz is the Chair of our Advisory Board and a recognized Business Development and International Business Relations Professional. Kambeitz is the original founder of Carbon RX and currently serves as the Executive Chairman of the Board,. Throughout his four-decade career as an entrepreneur, Kambeitz played a founding role in many other Canadian and United States companies.<br/><br/>Kambeitz has over 17-years’ experience in carbon capture and CO management, focusing on providing technical and organizational solutions for the Environmental, Social & Governance (ESG)."
}, {
  id: 8,
  name: "Jorge A. Simmonds",
  avatar: george,
  role: "Advisor",
  bio: "Founder of Analytics Band, MR. Simmonds leads a team of analysts, developers, and data scientists offering business intelligence and full stack engineering solutions to clients spanning industries from digital advertising to space tourism. Having worked with industry leaders such as ESPN, Turner Broadcasting and Home Depot, he has developed a wide array of knowledge and experience in business strategy and analytics.",
}, {
  id: 9,
  name: "Johnny Din",
  avatar: johnny,
  role: "Advisor",
  bio: "Mr. Din is the CEO of Cycamore Capital, a real estate investment and development platform in New York City.  He received a Bachelor of Arts in Business Economics from the University of California Los Angeles, graduating with Honors, and was a Riordan MBA Fellow from the UCLA Anderson School of Management. He holds a Certificate in Hotel Operations from New York University and a Master of Science in Real Estate Development from Columbia University, where he was the President of the MSRED Student Council and a recipient of the Scholastic Performance Award given to the top 3 graduates. Mr. Din is also an adjunct assistant professor at Columbia University and is currently pursuing a Global Executive Doctor of Education from University of Southern California.",
}]

