import React from "react";
import styles from "./../../../sass/landing/about.module.scss";
import classNames from "classnames";
import ViridiusIcon from "./../ViridiusIcon";
import { Typography } from "@mui/material";
import blockchain from '../../../Landing/assets/icon-blockchain.png';
import sc from '../../../Landing/assets/icon-smartcontract.png';
import certification from '../../../Landing/assets/icon-certification.png';

const Body = () => {
  return (
    <>
      <div className={classNames(styles["about"], "container")}>
        <div className="row">
          <div className="col-md-5 col-12">
            <ViridiusIcon icon={null}/>
            <h3>Structure, Governance & Certification</h3>
          </div>
          <div className={classNames(styles["intro"], "col-md-7 col-12 ps-md-5 ps-3")}>
            <Typography variant="body1">
              The Pure Sky DAO is controlled by holders of their PURE Tokens (“PRTs”). PRT holders are incentivized via a profit sharing that keeps member interests in focus. Each PRT holder will formally vote on varios decisions for the organization, split into varios subcommittees.
            </Typography>
          </div>
        </div>
        <div className={classNames(styles["features"], "row")}>
          <div className="col-lg-4 col-12">
            <div className={classNames(styles["item"])}>
              <img 
                src={blockchain}
                className="img-fluid" 
                alt="Illustration"
              />
              <h4>A Decentralized<br/>Process</h4>
              <Typography variant="body1">
                This decentralized process is governed by the Ethereum blockchain, which stores all of Pure Sky's financial records and program rules. Blockchains provide transparency so that each investor can see every detail of the company’s history. It’s a record that promotes accountability and verification.
              </Typography>
            </div>
          </div>
          <div className="col-lg-4 col-12 my-3 my-lg-0">
            <div className={classNames(styles["item"])}>
              <img 
                src={sc}
                className="img-fluid" 
                alt="Illustration"
              />
              <h4>Smart<br/>Contracts</h4>
              <Typography variant="body1">
                A smart contract is a software program that executes various objectives once certain criteria or catalysts are met. The beauty of this structure is that investors don’t have to place blind trust in the executive team. This means that potential investors only have to believe in the established, publicly available smart contracts rather than the decisions of the executive team.
              </Typography>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className={classNames(styles["item"])}>
              <img 
                src={certification}
                className="img-fluid" 
                alt="Illustration"
              />
              <h4>Certification</h4>
              <Typography variant="body1">
                Each carbon credit project proposed and considered by Pure Sky is peer-reviewed and thus certified by an open committee of well-qualified individuals in the industry. This means that every carbon credit project in the Pure Sky realm is in the upper echelon of the industry.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classNames(styles["revenue"])}>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center">
              <h3>Revenue Generation</h3>
              <Typography>Pure Sky benefits from a multi-facted revenue stream that consists of four channels.</Typography>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-12 col-md-5">
              <div className={classNames(styles["item"])}>
                <ViridiusIcon icon={null}/>
                <h4>Retirement<br/>Fees</h4>
                <Typography>
                  When a business purchases a carbon credit through the Pure Sky platform, it’s doing so in order to “retire” it - using it to offset carbon emissions. When a company retires a carbon credit purchased in a Pure Sky transaction, Pure Sky receives a retirement fee.
                </Typography>
              </div>
              <div className={classNames(styles["item"])}>
                <ViridiusIcon icon={null}/>
                <h4>Platform<br/>Transactions</h4>
                <Typography>
                  Each time a carbon credit transaction is executed, Pure Sky receives a transaction fee. Pure Sky is incentivized to bring the best carbon credit projects to the platform because they drive more transactions.
                </Typography>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className={classNames(styles["item"])}>
                <ViridiusIcon icon={null}/>
                <h4>Issuance<br/>Fees</h4>
                <Typography>
                  Pure Sky receives each time a carbon credit from a registered project. For each carbon credit Pure Sky issues to a registered project a fee is charged.
                </Typography>
              </div>
              <div className={classNames(styles["item"])}>
                <ViridiusIcon icon={null}/>
                <h4>Registration<br/>Fees</h4>
                <Typography>
                  Each time a carbon credit Project requests registration with Pure Sky, the company receives a registration fee.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
