import React, {useEffect} from "react";
import Body from "./Landing/components/About/Body";
import Footer from "./Landing/components/Footer";
import Hero from "./Landing/components/About/Hero";
import Header from './Header';

const About = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header/>
      <Hero/>
      <Body />
      <Footer/>
    </>
  );
};

export default About;
