import React, { useState } from "react";
import styles from "./../../../sass/landing/carbon-market.module.scss";
import classNames from "classnames";
import IconButton from "../IconButton";
import ViridiusIcon from "../ViridiusIcon";
import SubscribeModal from "../SubscribeModal";
import { Typography } from "@mui/material";
import accent_background from '../../../Landing/assets/accent-background.png';
import { ArrowTopRightBoldBox  } from "mdi-material-ui";

const Body = () => {
  const [open, setOpen] = useState(false);

  const handleDeckPaywall = () => {
    const isSubbed = localStorage.getItem("subbed");
    if(isSubbed) {
      window.open("/PureSkyPitchDeck-V2.pdf");
    } else {
      setOpen(true);
    }
  }

  return (
    <>
      <img src={accent_background} className="credit-market-background-1 img-fluid d-sm-block d-none" alt="Accent Background 1"/>
      <img src={accent_background} className="credit-market-background-2 img-fluid d-sm-block d-none" alt="Accent Background 2"/>
      <div className={classNames(styles["carbon-market"], "container")}>
        <div className="row justify-content-between">
          <div className="col-md-5 col-12">
            <ViridiusIcon icon={null}/>
            <h3>What does net-zero really mean?</h3>
            <Typography variant="body1">
              Simply put, a company reaches “net-zero” when the amount of greenhouse gases it emits is matched by the amount it removes - also referred to as carbon neutral. The way the vast majority of companies are reaching net-zero is through purchasing carbon credits. But who is actually following through and not simply “greenwashing?” In other words, buying their way to “green publicity.”
            </Typography>
          </div>
          <div className={classNames(styles["offset"], "col-md-5 col-12")}>
            <ViridiusIcon icon={null}/>
            <h3>What is a Carbon Credit?</h3>
            <Typography variant="body1">
              A carbon credit is a permit signifying one ton of CO2 that’s been removed from the atmosphere. (According to the Environmental Defense Fund, that credit would offset what is equivalent to a 2,400-mile drive in terms of carbon dioxide emissions.) Essentially, carbon credits have created a marketplace between companies or individuals emitting CO2 and companies or individuals removing CO2 from the atmosphere.
            </Typography>
          </div>
        </div>
      </div>
      <div className={classNames(styles["bleed"])}>
        <div className={classNames(styles["carbon-market"], "container")}>
          <div className="row">
            <div className="col-md-6 col-12">
              <h3>Voluntary Carbon<br/>Credit Market</h3>
              <ul>
                <li className="d-flex">
                  <ArrowTopRightBoldBox/>
                  <div>
                    <Typography variant="body1">The voluntary carbon<br/>offsets market size was</Typography>
                    <h4>$305.8M in 2022</h4>
                  </div>
                </li>
                <li className="d-flex">
                  <ArrowTopRightBoldBox/>
                  <div>
                    <Typography variant="body1">Forecasts project the value of<br/>the industry to be between</Typography>
                    <h4>$10-25B by 2030</h4>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-12">
              <Typography variant="body1">
                The voluntary carbon credit market is comprised of companies and individuals exchanging carbon credits between CO2 removers and emitters. This market exists thanks to governments clamping down on pollution through emission requirements.
              </Typography>
              <button className="naked-button" onClick={() => handleDeckPaywall()}>
                <IconButton icon="summary" copy={"Learn More"}/>
              </button>
            </div>
          </div>
          
        </div>
      </div>
      <SubscribeModal
        isOpened={open}
        closeModal={() => setOpen(false)}
        successUrl="/PureSkyPitchDeck-V2.pdf"
        submitButtonLabel="Learn More"
      />
    </>
  );
};

export default Body;
