import React from "react";
import Footer from "./Landing/components/Footer";
import styles from "./sass/subpage.module.scss";
import classNames from "classnames";
import { 
  Divider,
  Typography,
} from "@mui/material";
import Header from './Header';
import ViridiusIcon from "./Landing/components/ViridiusIcon";
import IconButton from "./Landing/components/IconButton";

const Faq = (props) => {
  return (
    <>
      <Header/>
      <div className={classNames(styles["subpage"], styles["membership"],"container")}>
        <div className="row">
          <div className="col-md-5 col-12">
            <ViridiusIcon icon={null}/>
            <h3>Become a member of the world’s first decentralized carbon registry!</h3>
            <button 
              className="naked-button mt-4 mb-5"
              onClick={() => window.open("https://registry.puresky.earth/")}
            >
              <IconButton icon="shimmer" copy={"Become a Member"}/>
            </button>
          </div>
          <div className="col-md-7 col-12 ps-md-5 ps-3">
            <Typography variant="body1" className={classNames(styles["intro"], "mb-5")}>
              At Pure Sky, you are in the driver’s seat and have a unique opportunity to create change. As a founding member you will have access to Pure Sky’s community of carbon credit influences and service providers. You and your vote will shape the carbon credit industry to make it stronger, more transparent, and help transform the world.
            </Typography>
            <div className={classNames(styles["panel"], "mb-5")}>
              <Typography variant="h4" className="mb-3">Membership Benefits</Typography>
              <Typography variant="body1">
                At Pure Sky, you are in the driver’s seat and have a unique opportunity to create change. As a founding member you will have access to Pure Sky’s community of carbon credit influences and service providers. You and your vote will shape the carbon credit industry to make it stronger, more transparent, and help transform the world.
              </Typography>
            </div>
            <div className={classNames(styles["panel"])}>
              <Typography variant="h4" className="mb-3">Membership Privileges</Typography>
              <Typography variant="body1">
                Only members of Pure Sky will be allowed to transact using the registry. Members will have an inside look at emerging projects and trends in the carbon credit ecosystem. Service providers will have access to the entire Pure Sky network of influencers, decision makers, and leaders transacting in carbon credits.
              </Typography>
              <Divider className={classNames(styles["divider"], "my-4")}/>
              <Typography variant="body1">Other benefits:</Typography>
              <ul>
                <li>Recognition on the Pure Sky Website;</li>
                <li>Invitations to exclusive Pure Sky events and related activities related to the registry.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mb-5 justify-content-center">
          <div className="col-md-8 col-12">
            <Typography variant="body1" className="mb-2">
          
            </Typography>
          </div>  
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Faq;
