import React from "react";
import Footer from "./Landing/components/Footer";
import styles from "./sass/subpage.module.scss";
import classNames from "classnames";
import { 
  Typography,
} from "@mui/material";
import Header from './Header';
import ViridiusIcon from "./Landing/components/ViridiusIcon";
import standard from './Landing/assets/icon-standard.png';

const Projects = () => {
  return (
    <>
      <Header/>
      <div className={classNames(styles["subpage"], styles["standard"], "container")}>
        <div className="row my-5">
          <div className="col-12">
            <ViridiusIcon icon={null}/>
            <Typography variant="h3">Projects</Typography>
          </div>
        </div>
        <div className="row mb-5 justify-content-center">
          <div className="col-md-8 col-12">
            <div className="row">
              <div className="col-md-7 col-12">
                <div 
                  className={classNames(styles["download"], "d-flex align-items-center")}
                  onClick={() => window.open("/2024-03-15 Carbon Rx Prairies Cropland Project - PureSky Submission.pdf")}
                  >
                  <img src={standard} alt="Pure Sky Standard"/>
                  <div>
                    <Typography variant="h4">Carbon RX Prairie Cropland Project</Typography>
                    <Typography variant="body2">Published on March 15, 2024 &mdash; <i>Version 1.0</i></Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Projects;
